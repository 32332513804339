


































import {
  Component, Vue, PropSync, Prop,
} from 'vue-property-decorator';

@Component({ name: 'ModalError' })
export default class ModalError extends Vue {
  @PropSync('isOpen', { type: Boolean }) synced_is_open!: boolean;

  @Prop({ default: 'Título del error' }) title_modal!: string | null;

  @Prop({ default: 'Texto de acompañamiento para la modal de error.' }) text_modal!: string | null;

  accept = null;
}
