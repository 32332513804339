


































































import { Component, Vue, PropSync } from 'vue-property-decorator';

@Component({ name: 'TermsAndConditions' })
export default class TermsAndConditions extends Vue {
  @PropSync('isOpen', { type: Boolean }) synced_is_open!: boolean;

  acceptTermsAndConditions() {
    this.synced_is_open = false;
    this.$emit('handleTermsAndConditions', true);
  }
}
