





















































































import { Vue, Component } from 'vue-property-decorator';

@Component({ name: 'FooterSocialNetworks' })
export default class FooterSocialNetworks extends Vue {}

