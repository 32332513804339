// Domain
import { InvalidPassword } from '../../../exceptions';

const DEFAULT_BLACKLIST_SUBSTRINGS = ['columbus'];

export default class BlacklistRule {
  static validate(password: string, custom_blacklist: Array<string>) {
    const blacklist = [
      ...custom_blacklist.map((substring) => substring.toLowerCase()),
      ...DEFAULT_BLACKLIST_SUBSTRINGS,
    ];
    const blacklist_length = blacklist.length;
    const normalized_password = password.toLowerCase();

    for (let index = 0; index < blacklist_length; index++) {
      if (normalized_password.includes(blacklist[index])) {
        throw new InvalidPassword('InvalidPassword');
      }
    }
  }
}
