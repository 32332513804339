






































































import { Component, Vue, PropSync } from 'vue-property-decorator';

@Component({ name: 'NoticeOfPrivacy' })
export default class NoticeOfPrivacy extends Vue {
  @PropSync('isOpen', { type: Boolean }) synced_is_open!: boolean;

  acceptNoticeOfPrivacy() {
    this.synced_is_open = false;
    this.$emit('handleNoticeOfPrivacy', true);
  }
}
