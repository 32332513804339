



























































import { Component, Vue, PropSync } from 'vue-property-decorator';

@Component({ name: 'DigitalMedia' })
export default class DigitalMedia extends Vue {
  @PropSync('isOpen', { type: Boolean }) synced_is_open!: boolean;

  acceptDigitalMedia() {
    this.synced_is_open = false;
    this.$emit('handleDigitalMedia', true);
  }
}
