















































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import SignUpViewModel from '@/vue-app/view-models/pages/sign-up-view-model';
import FooterSocialNetworks from '@/vue-app/components/onboarding/FooterSocialNetworks.vue';
import ModalError from '@/vue-app/components/onboarding/alerts/ModalError.vue';
import TermsAndConditions from '@/vue-app/components/onboarding/sign-up/TermsAndConditions.vue';
import DigitalMedia from '@/vue-app/components/onboarding/sign-up/DigitalMedia.vue';
import NoticeOfPrivacy from '@/vue-app/components/onboarding/sign-up/NoticeOfPrivacy.vue';
import ExistentEmail from '@/vue-app/components/authentication/ExistentEmail.vue';

@Component({
  name: 'SignUp',
  components: {
    FooterSocialNetworks,
    ModalError,
    TermsAndConditions,
    NoticeOfPrivacy,
    DigitalMedia,
    ExistentEmail,
  },
})
export default class SignUp extends Vue {
  sign_up_view_model = Vue.observable(new SignUpViewModel(this));
}
