












































import { Vue, Component, PropSync } from 'vue-property-decorator';
import ExistentEmailViewModel
  from '@/vue-app/view-models/components/authentication/existent-email-view-model';

@Component({ name: 'ExistentEmail' })
export default class ExistentEmail extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  is_open!: boolean;

  existent_email_view_model = Vue.observable(new ExistentEmailViewModel())
}
