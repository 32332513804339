// Domain
import { InvalidPassword } from '../../../exceptions';

export default class NoConsecutiveOrRepeatedCharactersRule {
  static validate(password: string) {
    const normalized_password = password.toLowerCase();
    const limit_of_equal_characters = 3;
    const limit_of_consecutive_characters = 3;
    const password_check_iterations = password.length - 3;
    const password_check_consecutive_iterations = limit_of_consecutive_characters - 1;

    for (let index = 0; index < password_check_iterations; index++) {
      const substring = normalized_password.substring(index, index + limit_of_equal_characters);
      const substring_length = substring.length;
      let not_consecutive_ascendant_characters;
      let not_consecutive_descendant_characters;

      if (substring === substring[0].repeat(limit_of_equal_characters)) {
        throw new InvalidPassword('InvalidPassword');
      }

      for (let sub_index = 0; sub_index < password_check_consecutive_iterations; sub_index++) {
        const ascii_for_a = substring.charCodeAt(sub_index);
        const ascii_for_b = substring.charCodeAt(sub_index + 1);
        const ascii_for_y = substring.charCodeAt(substring_length - 2 - sub_index);
        const ascii_for_z = substring.charCodeAt(substring_length - 1 - sub_index);
        const consecutive_ascendant_characters = ascii_for_a + 1 === ascii_for_b;
        const consecutive_descendant_characters = ascii_for_y - 1 === ascii_for_z;

        if (!consecutive_ascendant_characters) {
          not_consecutive_ascendant_characters = true;
        }

        if (!consecutive_descendant_characters) {
          not_consecutive_descendant_characters = true;
        }
      }

      if (!not_consecutive_ascendant_characters || !not_consecutive_descendant_characters) {
        throw new InvalidPassword('InvalidPassword');
      }
    }
  }
}
