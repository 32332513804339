// Domain
import {
  BlackListRule,
  MinimalRequirementsRule,
  NoConsecutiveOrRepeatedCharactersRule,
} from './rules';

export default class PasswordValidator {
  static rules: Array<any> = [
    MinimalRequirementsRule,
    BlackListRule,
    NoConsecutiveOrRepeatedCharactersRule,
  ];

  static validate(password: string, blacklist: Array<string> = []): boolean {
    try {
      this.rules.forEach((rule) => {
        rule.validate(password, blacklist);
      });

      return true;
    } catch {
      return false;
    }
  }
}
